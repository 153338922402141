import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Link, useStaticQuery, graphql } from "gatsby";
import LinkParser from "../utils/link-parser";
import { detect } from "detect-browser";

import MobileMenu from "./mobileMenu";

import MenuIcon from "../assets/images/icons/menu.svg";

const HeaderSpacer = styled.div`
  width: 100%;
  height: 100px;
`;

const HeaderContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  margin: 0px 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  width: 100%;
  display: block;
`;

const MobileContent = styled.div`
  display: none;
  z-index: 9;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 70px;
    margin: 0;
    padding: 0px 10px;
  }
`;

const MobileMenuBg = styled(motion.div)`
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgb(45 53 69 / 0.82);
  backface-visibility: hidden;
  transform: translateZ(0) scale(1, 1);
  will-change: transform;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-font-smoothing: subpixel-antialiased;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  width: 100%;
  height: 70px;
  ${(props) =>
    !props.notSupported &&
    css`
      background-filter: unset;
      background-color: #2c3445;
    `}
`;

const LeftContent = styled(motion.div)``;

const CenterContent = styled.div``;

const RightContent = styled(motion.div)`
  display: flex;
  font-family: "GT Walsheim", Arial, Helvetica, sans-serif;

  margin-right: 40px;
  padding: 17px;
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
`;

const RightContentBg = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgb(45 53 69 / 0.82);
  backface-visibility: hidden;
  transform: translateZ(0) scale(1, 1);
  will-change: transform;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-font-smoothing: subpixel-antialiased;
  ${(props) =>
    !props.notSupported &&
    css`
      background-filter: unset;
      background-color: #2c3445;
    `}
`;

const RightContentMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 40px;
  }
`;

const MobileMenuIconContainer = styled.div`
  height: 35px;
  width: 35px;
  z-index: 9;
`;

const MenuIconDesktopContainer = styled(motion.div)`
  height: 35px;
  width: 35px;
  z-index: 9;
  margin-left: 15px;
  margin-top: -4px;
  cursor: pointer;
`;

const LogoContainer = styled(motion.div)`
  height: 60px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Metropolis", Arial, Helvetica, sans-serif;

  font-weight: bold;
  user-select: none;
  cursor: pointer;

  overflow: hidden;

  ${(props) =>
    props.showBackground &&
    css`
      backdrop-filter: saturate(180%) blur(20px);
      background-color: rgb(45 53 69 / 0.82);
      backface-visibility: hidden;
      transform: translateZ(0) scale(1, 1);
      will-change: transform;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -webkit-font-smoothing: subpixel-antialiased;
    `};
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileLogoContainer = styled(motion.div)`
  display: none;
  @media (max-width: 768px) {
    height: 60px;
    width: 60px;
    background-color: #2c3445;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Metropolis", Arial, Helvetica, sans-serif;

    font-weight: bold;
    user-select: none;
    cursor: pointer;
    overflow: hidden;
  }
`;

const ExpandedLogoContainer = styled(motion.div)`
  padding: 0px 10px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 18px;
`;

const TopLayerLogo = styled.div`
  color: ${(props) => props.color};
  white-space: nowrap;
`;

const BottomLayerLogo = styled.div`
  color: #fde115;
  white-space: nowrap;
`;

const CompressedLogoContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 22px;
  color: #fff;
  font-size: 26px;
  font-family: "Metropolis", Arial, Helvetica, sans-serif;

  font-weight: bold;
  line-height: 24px;
  font-size: 28px;
  letter-spacing: 2px;
  padding-top: 3px;
  padding-left: 3px;
  span {
    color: #fde115;
  }
`;

const CompressedLogoContainerMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 22px;
  z-index: 9;
  width: 50px;
  height: 50px;
  font-size: 24px;
  letter-spacing: 3px;
  text-align: center;
  font-family: "Metropolis", Arial, Helvetica, sans-serif;

  font-weight: bold;
  padding-left: 3px;
  color: ${(props) => props.color};
  span {
    color: #fde115;
  }
`;

const MenuItem = styled(motion.div)`
  padding: 0px 10px;
  cursor: pointer;
  position: relative;
  transition: 400ms;
  text-decoration: none;
  z-index: 2;
  font-weight: 600;
  color: ${(props) => props.color};
  ::after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: "";
    display: block;
    height: 1px;
    left: 0%;
    margin-top: 0px;
    position: absolute;
    background: #fde115;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
    margin-left: 5%;
  }

  :hover {
    ::after {
      width: 90%;
      left: 0;
    }
  }
`;

const MenuItemLink = styled(Link)`
  text-decoration: none;
  color: unset;
  z-index: 2;
`;

const LogoContainerLink = styled(Link)`
  width: 60px;
  height: 100%;
  text-decoration: none;
  color: unset;
`;

const ContainerAnim = {
  compressed: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2,
      duration: 1,
    },
  },
  expanded: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 2.2,
      duration: 0.4,
    },
  },
};

const LogoIntroAnim = {
  compressed: {
    opacity: 0,
    y: -40,
    transition: {
      delay: 0.2,
      duration: 1,
    },
  },
  expanded: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.5,
      duration: 0.4,
    },
  },
};

const RightContentAnim = {
  compressed: {
    opacity: 1,
  },
  expanded: {
    opacity: 1,
    transition: {
      staggerChildren: 0.075,
      delayChildren: 1.5,
    },
  },
};

const RightContentItemAnim = {
  compressed: { opacity: 0, y: -20 },
  expanded: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
    },
  },
};

const LogoAnim = {
  compressed: {
    width: "60px",
    height: "60px",
    transition: {
      duration: 0.4,
      type: "tween",
    },
  },
  expanded: {
    width: "225px",
    height: "60px",
    transition: {
      duration: 0.6,
      type: "tween",
    },
  },
};

const CompressedLogoAnim = {
  compressed: {
    opacity: 1,
    width: "60px",
    transition: {
      delay: 0.2,
      duration: 1,
    },
  },
  expanded: {
    opacity: 0,
    width: "300px",
    transition: {
      delay: 0.2,
      duration: 1,
    },
  },
};

const ExpandedLogoAnim = {
  compressed: {
    opacity: 0,
    transition: {
      delay: 0.2,
      duration: 1,
    },
  },
  expanded: {
    opacity: 1,
    transition: {
      delay: 0.3,
      duration: 0.4,
    },
  },
};

const MobileMenuBgAnim = {
  compressed: {
    opacity: 0,
    transition: {
      duration: 0.4,
    },
  },
  expanded: {
    opacity: 1,
    transition: {
      duration: 0.4,
    },
  },
};

const RightContentBgAnim = {
  hidden: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0.2,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      duration: 0.2,
    },
  },
};

const MenuIconContainerAnim = {
  compressed: { opacity: 0, y: -20 },
  expanded: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 2.1,
      duration: 0.35,
    },
  },
};

function AlternateMenu({
  homeHeaderItemColor,
  homeHeaderLoginItemColor,
  transitionHeight,
  mobileMenuIconColor,
  blueLogoMobileTop,
  blueLogoTop,
  menuItemsTopColor,
  handleDekstopMenuToggle,
  setDesktopMenuActive,
}) {
  const [showExpandedLogo, setShowExpandedLogo] = useState(true);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [doAnim, setDoAnim] = useState(true);
  const [scrolledPast, setScrolledPast] = useState(false);

  const data = useStaticQuery(graphql`
    query MenuData {
      menu: allStoryblokEntry(filter: { field_component: { eq: "menu" } }) {
        edges {
          node {
            content
          }
        }
      }
    }
  `);

  let menuData = JSON.parse(data.menu.edges[0].node.content);
  menuData = menuData.menuItems;

  function toggleMobileMenu() {
    if (mobileMenuActive) {
      setMobileMenuActive(false);
    } else {
      setMobileMenuActive(true);
    }
  }

  useScrollPosition(({ prevPos, currPos }) => {
    let vh = Math.round(window.innerHeight / 100);
    let fullVh = vh * transitionHeight;
    let fullVhConv = fullVh - fullVh * 2;
    if (currPos.y < fullVhConv) {
      setScrolledPast(true);
      setShowExpandedLogo(false);
    } else {
      setScrolledPast(false);
      setShowExpandedLogo(true);
    }
  });

  function handleHoverStart() {
    if (scrolledPast) {
      setShowExpandedLogo(true);
    }
  }

  function handleHoverEnd() {
    if (scrolledPast) {
      setShowExpandedLogo(false);
    }
  }

  let browser = detect();

  return (
    <React.Fragment>
      {mobileMenuActive ? (
        <MobileMenu
          active={mobileMenuActive}
          toggleMobileMenu={toggleMobileMenu}
        />
      ) : null}
      <HeaderSpacer />
      <HeaderContainer
        initial="compressed"
        animate={doAnim ? "expanded" : "compressed"}
        variants={ContainerAnim}
      >
        <LeftContent
          initial="compressed"
          animate={doAnim ? "expanded" : "compressed"}
          variants={LogoIntroAnim}
        >
          <LogoContainerLink to={"/"}>
            <LogoContainer
              initial="expanded"
              animate={showExpandedLogo ? "expanded" : "compressed"}
              variants={LogoAnim}
              onHoverStart={() => handleHoverStart()}
              onHoverEnd={() => handleHoverEnd()}
              showBackground={scrolledPast ? true : false}
            >
              {showExpandedLogo ? (
                <ExpandedLogoContainer
                  initial={{
                    opacity: 1,
                  }}
                  animate={showExpandedLogo ? "expanded" : "compressed"}
                  variants={ExpandedLogoAnim}
                >
                  <TopLayerLogo
                    color={
                      scrolledPast
                        ? "#FFFFFF"
                        : blueLogoTop
                        ? "#2C3445"
                        : "#FFFFFF"
                    }
                  >
                    <div>Leeds United</div>
                  </TopLayerLogo>
                  <BottomLayerLogo>
                    <div>Supporters' Trust</div>
                  </BottomLayerLogo>
                </ExpandedLogoContainer>
              ) : (
                <CompressedLogoContainer
                  initial="expanded"
                  animate={showExpandedLogo ? "expanded" : "compressed"}
                  variants={CompressedLogoAnim}
                >
                  <div>LU</div>
                  <div>
                    <span>ST</span>
                  </div>
                </CompressedLogoContainer>
              )}
            </LogoContainer>
          </LogoContainerLink>
        </LeftContent>
        <CenterContent></CenterContent>

        <RightContent
          initial="compressed"
          animate={doAnim ? "expanded" : "compressed"}
          variants={RightContentAnim}
        >
          {menuData.map((item, i) => {
            if (item.accentType) {
              return (
                <MenuItem
                  color={scrolledPast ? "#FDE115" : homeHeaderLoginItemColor}
                  variants={RightContentItemAnim}
                  key={i}
                >
                  <MenuItemLink to={LinkParser(item.link)}>
                    {item.title}
                  </MenuItemLink>
                </MenuItem>
              );
            } else {
              return (
                <MenuItemLink to={LinkParser(item.link)}>
                  <MenuItem
                    color={scrolledPast ? "#FFFFFF" : homeHeaderItemColor}
                    variants={RightContentItemAnim}
                    key={i}
                  >
                    {item.title}
                  </MenuItem>
                </MenuItemLink>
              );
            }
          })}
          <MenuIconDesktopContainer
            onClick={() => setDesktopMenuActive(true)}
            initial="compressed"
            animate={doAnim ? "expanded" : "compressed"}
            variants={MenuIconContainerAnim}
          >
            <MenuIcon
              fill={
                scrolledPast
                  ? "#fff"
                  : homeHeaderItemColor
                  ? homeHeaderItemColor
                  : "#fff"
              }
              stroke={
                scrolledPast
                  ? "#fff"
                  : homeHeaderItemColor
                  ? homeHeaderItemColor
                  : "#fff"
              }
            />
          </MenuIconDesktopContainer>
          <RightContentBg
            initial="hidden"
            animate={scrolledPast ? "visible" : "hidden"}
            variants={RightContentBgAnim}
            notSupported={
              browser.name === "chrome"
                ? true
                  ? browser.name === "safari"
                  : true
                : false
            }
          />
        </RightContent>
      </HeaderContainer>
      <MobileContent>
        <CompressedLogoContainerMobile
          color={
            scrolledPast ? "#FFFFFF" : blueLogoMobileTop ? "#2C3445" : "#FFFFFF"
          }
        >
          <Link to={"/"} style={{ textDecoration: "none", color: "unset" }}>
            <div>LU</div>
            <div>
              <span>ST</span>
            </div>
          </Link>
        </CompressedLogoContainerMobile>

        <MobileMenuIconContainer onClick={() => setMobileMenuActive(true)}>
          <MenuIcon
            fill={scrolledPast ? "#FFFFFF" : mobileMenuIconColor}
            stroke={scrolledPast ? "#FFFFFF" : mobileMenuIconColor}
          />
        </MobileMenuIconContainer>
        <MobileMenuBg
          initial="expanded"
          animate={scrolledPast ? "expanded" : "compressed"}
          variants={MobileMenuBgAnim}
          notSupported={
            browser.name === "chrome"
              ? true
                ? browser.name === "safari"
                : true
              : false
          }
        />
      </MobileContent>
    </React.Fragment>
  );
}

export default AlternateMenu;
