import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link, useStaticQuery, graphql } from "gatsby";
import ScrollContainer from "react-indiana-drag-scroll";

import NewsItem from "./newsItem";

const NewsItemsContainer = styled(motion.div)`
  height: 280px;
  width: 100%;
  margin-top: 50px;
  display: flex;
  @media (max-width: 768px) {
    height: 230px;
  }
`;

const StyledScrollContainer = styled(ScrollContainer)`
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const NewsItemAnimLayer = styled(motion.div)`
  height: 100%;
  width: 280px;
  min-width: 280px;
  margin-right: 30px;
  @media (max-width: 768px) {
    width: 230px;
    min-width: 230px;
    margin-right: 15px;
  }
`;

const NewsContentAnim = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.06,
      delayChildren: 0.7,
    },
  },
};

const NewsContentItemAnim = {
  hidden: { opacity: 0, x: 800 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

function MenuRecentArticles({ closeMenu, active }) {
  const data = useStaticQuery(graphql`
    query MenuRecentArticles {
      recentArticles: allStoryblokEntry(
        filter: { field_component: { eq: "article" } }
        sort: { fields: [field_dateWritten_string], order: DESC }
      ) {
        edges {
          node {
            name
            full_slug
            field_dateWritten_string
            content
          }
        }
      }
    }
  `);

  return (
    <NewsItemsContainer
      initial="hidden"
      animate={active ? "visible" : "hidden"}
      variants={NewsContentAnim}
    >
      <StyledScrollContainer vertical={false} hideScrollbars={false}>
        {data.recentArticles.edges.slice(0, 6).map((recentArticle, i) => (
          <NewsItemAnimLayer variants={NewsContentItemAnim} key={i}>
            <NewsItem data={recentArticle.node} closeMenu={closeMenu} />
          </NewsItemAnimLayer>
        ))}
      </StyledScrollContainer>
    </NewsItemsContainer>
  );
}

export default MenuRecentArticles;
