import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";
import { Link } from "gatsby";

import ClientOnly from "../utils/clientOnly";

const WORDS = ["stronger", "louder", "better", "united"];

const FooterContainerOuter = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const FooterContainer = styled.div`
  width: 100%;
  background-color: #2c3445;
  font-family: "GT Walsheim", Arial, Helvetica, sans-serif;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 82px;
  @media (max-width: 767px) {
    padding-top: 32px;
  }
`;

const FooterContainerInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1170px;
  width: 100%;
  margin-bottom: 73px;
  padding: 0px 67px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 43px;
    padding: 0px 25px;
  }
  @media (max-width: 440px) {
    padding: 0px 20px;
  }
`;

const LeftContent = styled.div``;

const RightContent = styled.div``;

const TaglineContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  flex-direction: column;

  @media (max-width: 650px) {
    align-items: flex-start;
    margin-top: 40px;
  }
`;

const Tagline = styled.div`
  font-size: 72px;
  line-height: 87px;
  letter-spacing: -0.5px;
  color: #fff;
  font-weight: 600;
  font-family: "Poppins";
  span {
    color: #fde115;
  }
  @media (max-width: 1020px) {
    font-size: 50px;
    line-height: 57px;
    letter-spacing: -0.4px;
  }
  @media (max-width: 800px) {
    font-size: 36px;
    line-height: 47px;
  }
  @media (max-width: 767px) {
    font-size: 50px;
    line-height: 57px;
    letter-spacing: -0.4px;
  }
`;

const TaglineDivider = styled.div`
  width: 100px;
  height: 5px;
  background-color: #fff;
  margin-top: 40px;
  margin-bottom: 30px;
  @media (max-width: 650px) {
    margin-top: 25px;
  }
`;

const BaselineContent = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1170px;
  width: 100%;
  margin: 0 -67px;
  padding: 45px 67px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  flex-direction: column;
  position: relative;
  @media (max-width: 850px) {
    justify-content: flex-start;
    flex-direction: column;
  }
  @media (max-width: 767px) {
    padding: 25px 67px;
    font-size: 14px;
  }
  @media (max-width: 440px) {
    padding: 25px 37px;
  }
`;

const BaselineInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 850px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`;

const Designed = styled(motion.div)`
  color: #c5c1c1;
  a {
    color: #fff;
    text-decoration: none;
    transition: 400ms;
    :hover {
      color: #fde115;
      transition: 400ms;
    }
  }
`;

const Copyright = styled(motion.div)`
  color: #c5c1c1;
  @media (max-width: 850px) {
    margin-bottom: 0.25px;
  }
`;

const LinksContainer = styled(motion.div)`
  display: flex;
  width: 100%;

  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;

  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
  @media (max-width: 370px) {
    flex-direction: column;
    margin-top: 40px;
  }
`;

const MenuItem = styled(motion.div)`
  margin-bottom: 23px;
  cursor: pointer;
  color: #c5c1c1;
  font-size: 18px;
  line-height: 26px;
  transition: 400ms;
  font-family: "Poppins";
  font-weight: 500;
  text-decoration: none;
  :hover {
    transition: 400ms;
    color: #fde115;
  }
  @media (max-width: 860px) {
    margin-bottom: 13px;
    font-size: 16px;
  }
  a {
    color: #c5c1c1;
    text-decoration: none;
    :hover {
      transition: 400ms;
      color: #fde115;
    }
  }
`;

const MenuItemExternal = styled(motion.a)`
  margin-bottom: 23px;
  cursor: pointer;
  color: #c5c1c1;
  font-size: 18px;
  line-height: 26px;
  transition: 400ms;
  font-family: "Poppins";
  font-weight: 500;
  text-decoration: none;
  :hover {
    transition: 400ms;
    color: #fde115;
  }
  @media (max-width: 860px) {
    margin-bottom: 13px;
    font-size: 16px;
  }
`;

const Column = styled.div`
  margin-left: 40px;
  margin-top: 70px;
  @media (max-width: 767px) {
    margin-left: 0px;
    margin-right: 40px;
    margin-top: 40px;
  }
  @media (max-width: 370px) {
    margin-top: 0px;
  }
`;

const Divider = styled(motion.div)`
  height: 1px;
  width: 100%;
  background-color: rgba(216, 216, 216, 0.2);
  position: absolute;
  top: 0;
  left: 0;
`;

const TaglineAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: "easeIn",
    },
  },
};

const TitleAnim = {
  hidden: {
    opacity: 0,
    y: -10,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.45,
      duration: 0.3,
      ease: "easeIn",
    },
  },
};

const MenuContentAnim = {
  compressed: {
    opacity: 1,
  },
  expanded: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
      delay: 0.5,
    },
  },
};

const MenuContentItemAnim = {
  compressed: { opacity: 0, y: 20 },
  expanded: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.1,
    },
  },
};

const DividerAnim = {
  compressed: {
    width: "0%",
  },
  expanded: {
    width: "100%",
    transition: {
      delay: 0.6,
      duration: 0.4,
      ease: "easeIn",
    },
  },
};

const CopyrightAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      delay: 0.8,
      ease: "easeIn",
    },
  },
};

const DesignedAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      delay: 0.9,
      ease: "easeIn",
    },
  },
};

function Footer() {
  const [year, setyear] = useState("2020");

  useEffect(() => {
    var d = new Date();
    setyear(d.getFullYear());
  }, []);

  const [isInView, setIsInView] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });

  return (
    <FooterContainerOuter ref={ref}>
      <ClientOnly style={{ width: "100%" }}>
        <FooterContainer>
          <FooterContainerInner>
            <LeftContent>
              <TaglineContainer
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                variants={TaglineAnim}
              >
                <Tagline>
                  Together,
                  <br /> we're{" "}
                  <span>
                    {WORDS[Math.floor(Math.random() * WORDS.length)]}.
                  </span>
                </Tagline>
              </TaglineContainer>
            </LeftContent>
            <RightContent>
              <LinksContainer
                initial="compressed"
                animate={isInView ? "expanded" : "compressed"}
                variants={MenuContentAnim}
              >
                <Column>
                  <MenuItem key={1} variants={MenuContentItemAnim}>
                    <Link
                      to={"/about"}
                      style={{
                        textDecoration: "none!important",
                        color: "unset",
                      }}
                    >
                      About Us
                    </Link>
                  </MenuItem>
                  <MenuItem key={2} variants={MenuContentItemAnim}>
                    <Link
                      to={"/articles"}
                      style={{
                        textDecoration: "none!important",
                        color: "unset",
                      }}
                    >
                      Latest Articles
                    </Link>
                  </MenuItem>
                  <MenuItem key={3} variants={MenuContentItemAnim}>
                    <a
                      href={"https://murals.lufctrust.com"}
                      target={"_blank"}
                      style={{
                        textDecoration: "none!important",
                        color: "unset",
                      }}
                    >
                      Mural Map
                    </a>
                  </MenuItem>
                  <MenuItem key={4} variants={MenuContentItemAnim}>
                    <Link
                      to={"/membership"}
                      style={{
                        textDecoration: "none!important",
                        color: "unset",
                      }}
                    >
                      Membership
                    </Link>
                  </MenuItem>
                </Column>
                <Column>
                  <MenuItem key={5} variants={MenuContentItemAnim}>
                    <Link
                      to={"/contact"}
                      style={{
                        textDecoration: "none!important",
                        color: "unset",
                      }}
                    >
                      Contact Us
                    </Link>
                  </MenuItem>
                  <MenuItem key={6} variants={MenuContentItemAnim}>
                    <Link
                      to={"/contact"}
                      style={{
                        textDecoration: "none!important",
                        color: "unset",
                      }}
                    >
                      Press
                    </Link>
                  </MenuItem>
                  <MenuItem key={7} variants={MenuContentItemAnim}>
                    <Link
                      to={"/privacy-policy "}
                      style={{
                        textDecoration: "none!important",
                        color: "unset",
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </MenuItem>
                </Column>
              </LinksContainer>
            </RightContent>
          </FooterContainerInner>
          <BaselineContent>
            <Divider
              initial="compressed"
              animate={isInView ? "expanded" : "compressed"}
              variants={DividerAnim}
            />
            <BaselineInner>
              <Copyright
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                variants={CopyrightAnim}
              >
                Copyright Leeds United Supporters' Trust {year}
              </Copyright>
              <Designed
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                variants={DesignedAnim}
              >
                Designed and Created by{" "}
                <a href="https://www.joshcawthorne.com" target={"_blank"}>
                  Josh Cawthorne
                </a>
              </Designed>
            </BaselineInner>
          </BaselineContent>
        </FooterContainer>
      </ClientOnly>
    </FooterContainerOuter>
  );
}

export default Footer;
