import React, { useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { Link, useStaticQuery, graphql } from "gatsby";
import LinkParser from "../../utils/link-parser";

import CloseIcon from "../../assets/images/icons/closeIcon.svg";
import FacebookIcon from "../../assets/images/icons/facebook.svg";
import InstagramIcon from "../../assets/images/icons/instagram.svg";
import TwitterIcon from "../../assets/images/icons/twitter.svg";
import MenuRecentArticles from "./menuRecentArticles";

const DesktopMenuContainer = styled(motion.div)`
  z-index: 100;
  width: 100vw;
  height: 100%;
  min-height: 800px;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: left;
  top: 0;
  background-color: #2c3445b5;
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  backface-visibility: hidden;
  transform: translateZ(0) scale(1, 1);
  will-change: transform;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-font-smoothing: subpixel-antialiased;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;
`;

const DesktopMenuContainerInner = styled.div`
  width: 84.44444%;
  position: relative;
  padding: 48px 0 0;
  margin: 0 auto;
  /*margin: 0 0 0 calc((100% - 1216px) / 2);*/
  @media (max-width: 768px) {
    padding: 10px 0 0;
  }
`;

const TopLayer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 84.44444%;
  margin: 0 auto;
  padding: 48px 0 0;
  @media (max-width: 768px) {
    padding: 5px 0 0;
  }
`;

const BottomLayer = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    padding-bottom: 20px;

    z-index: 100000;
  }
  @media (max-height: 990px) {
    position: sticky;
  }
`;

const BottomLayerInner = styled.div`
  width: 84.44444%;
  margin: 0 auto;
  padding: 48px 0 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 25px;
  }
`;

const ExpandedLogoContainer = styled(Link)`
  text-transform: uppercase;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none !important;
`;

const TopLayerLogo = styled.div`
  color: ${(props) => props.color};
  white-space: nowrap;
`;

const BottomLayerLogo = styled.div`
  color: #fde115;
  white-space: nowrap;
`;

const LogoContainer = styled(motion.div)`
  height: 60px;
  width: 225px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Metropolis", Arial, Helvetica, sans-serif;

  font-weight: bold;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  font-feature-settings: "kern", "liga", "clig", "calt";
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
`;

const CloseIconContainer = styled(motion.div)`
  margin: 20px 0px;
  width: 50px;
  cursor: pointer;
  margin-right: -15px;
`;

const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const MenuItemsContainer = styled(motion.div)`
  height: 100%;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  @media (max-width: 1100px) {
    width: 300px;
  }
  @media (max-width: 850px) {
    width: 100%;
  }
`;

const MenuItemAnimLayer = styled(motion.div)`
  margin-bottom: 25px;
`;

const MenuItem = styled(Link)`
  color: #fff;
  font-size: 28px;
  font-weight: 300;
  line-height: 115%;

  cursor: pointer;
  text-decoration: none;
  transition: 400ms;
  :hover {
    color: #fde115;
    margin-left: 10px;
    transition: 400ms;
  }
`;

const NewsContainer = styled.div`
  width: calc(100% - 500px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  @media (max-width: 1100px) {
    width: calc(100% - 300px);
  }
  @media (max-width: 850px) {
    width: 100%;
    margin-top: 75px;
  }
`;

const NewsTitle = styled(motion.div)`
  color: #fff;
  font-size: 28px;
  margin-top: -40px;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  width: 200px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const SocialIconContainerOuter = styled.a`
  width: 20px;
  height: 20px;
  margin-right: 15px;
`;

const SocialIconContainer = styled(Link)`
  width: 20px;
  height: 20px;
  margin-right: 15px;
`;

const CopyrightText = styled.div`
  color: #fff;
  font-size: 10px;
  letter-spacing: 0.5px;

  line-height: 15px;
  a {
    color: #fde115;
    text-decoration: none;
  }
`;

const ContainerAnim = {
  hidden: {
    x: "100vw",
    transition: {
      duration: 0.3,
    },
  },
  visible: {
    x: "0",
    transition: {
      duration: 0.4,
      ease: "easeOut",
    },
  },
};

const MenuItemsAnim = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.075,
      delayChildren: 0.4,
    },
  },
};

const MenuItemAnim = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      ease: "easeIn",
    },
  },
};

const ArticleTextAnim = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.7,
      ease: "easeIn",
    },
  },
};

const BottomLayerAnim = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.9,
      ease: "easeIn",
    },
  },
};

const LogoAnim = {
  hidden: { opacity: 0, y: -20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
      ease: "easeIn",
    },
  },
};

const CloseAnim = {
  hidden: { opacity: 0, y: -20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.4,
      ease: "easeIn",
    },
  },
};

function DesktopMenu({ active, setDesktopMenuActive }) {
  const data = useStaticQuery(graphql`
    query DesktopMenuData {
      menu: allStoryblokEntry(
        filter: { field_component: { eq: "desktopFullScreenMenu" } }
      ) {
        edges {
          node {
            content
          }
        }
      }
    }
  `);

  let menuData = JSON.parse(data.menu.edges[0].node.content);

  function closeMenu() {
    setDesktopMenuActive(false);
  }

  return (
    <DesktopMenuContainer
      initial="hidden"
      animate={active ? "visible" : "hidden"}
      variants={ContainerAnim}
    >
      <TopLayer>
        <LogoContainer
          initial="hidden"
          animate={active ? "visible" : "hidden"}
          variants={LogoAnim}
        >
          <ExpandedLogoContainer to={"/"}>
            <TopLayerLogo color={"#FFFFFF"}>
              <div>Leeds United</div>
            </TopLayerLogo>
            <BottomLayerLogo>
              <div>Supporters' Trust</div>
            </BottomLayerLogo>
          </ExpandedLogoContainer>
        </LogoContainer>

        <CloseIconContainer
          initial="hidden"
          animate={active ? "visible" : "hidden"}
          variants={CloseAnim}
          onClick={() => closeMenu()}
        >
          <CloseIcon fill={"#fff"} strokeColor={"#fff"} strokeWidth={"5px"} />
        </CloseIconContainer>
      </TopLayer>
      <DesktopMenuContainerInner>
        <ContentContainer>
          <MenuItemsContainer
            initial="hidden"
            animate={active ? "visible" : "hidden"}
            variants={MenuItemsAnim}
          >
            {menuData.menuItem.map((item, i) => (
              <MenuItemAnimLayer variants={MenuItemAnim} key={i}>
                <MenuItem to={LinkParser(item.link)}>{item.title}</MenuItem>
              </MenuItemAnimLayer>
            ))}
          </MenuItemsContainer>
          <NewsContainer>
            <NewsTitle
              initial="hidden"
              animate={active ? "visible" : "hidden"}
              variants={ArticleTextAnim}
            >
              Latest Articles
            </NewsTitle>
            <MenuRecentArticles active={active} closeMenu={closeMenu} />
          </NewsContainer>
        </ContentContainer>
      </DesktopMenuContainerInner>
      <BottomLayer
        initial="hidden"
        animate={active ? "visible" : "hidden"}
        variants={BottomLayerAnim}
      >
        <BottomLayerInner>
          <SocialIcons>
            <SocialIconContainerOuter>
              <SocialIconContainer
                href="https://www.facebook.com/lufctrust"
                target="_blank"
              >
                <FacebookIcon fill={"#fff"} />
              </SocialIconContainer>
            </SocialIconContainerOuter>
            <SocialIconContainerOuter>
              <SocialIconContainer
                href="https://www.instagram.com/lufctrust"
                target="_blank"
              >
                <InstagramIcon fill={"#fff"} />
              </SocialIconContainer>
            </SocialIconContainerOuter>
            <SocialIconContainerOuter>
              <SocialIconContainer
                href="https://www.twitter.com/lufctrust"
                target="_blank"
              >
                <TwitterIcon fill={"#fff"} />
              </SocialIconContainer>
            </SocialIconContainerOuter>
          </SocialIcons>

          <CopyrightText>
            Copyright Leeds United Supporters' Trust 2020 - Present
          </CopyrightText>
        </BottomLayerInner>
      </BottomLayer>
    </DesktopMenuContainer>
  );
}

export default DesktopMenu;
