/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ pageDesc, lang, meta, title, sharingImage }) {
  let sharingImageParsed = sharingImage;
  /*useEffect(() => {
    if (sharingImage && !sharingImage.includes("a.storyblok.com")) {
      sharingImageParsed = "https://www.lufctrust.com/" + sharingImage;
    }
  }, []);*/
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        meta={[
          { name: `image`, content: sharingImageParsed },
          { property: `og:image`, content: sharingImageParsed },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:creator`,
            content: "@lufctrust",
          },
          {
            name: `twitter:title`,
            content: title,
          },

          {
            name: `twitter:image:src`,
            content: sharingImageParsed,
          },
        ].concat(meta)}
        defer={false}
      />
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
        <meta name="title" content={title} />
        <meta name="description" content={pageDesc} />
        <meta name="og:description" content={pageDesc} />
        <meta name="twitter:description" content={pageDesc} />
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {`
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://www.lufctrust.com",
            "logo": "https://www.lufctrust.com/logoCompact.png"
          `}
        </script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {`
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is the Leeds United Supporters' Trust?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>The Leeds United Supporters’ Trust (LUST) is an independent, democratic, not-for-profit organisation, committed to providing a voice for Leeds United fans – wherever they are.</p>"
        }
      }, {
        "@type": "Question",
        "name": "How do I become a member of the Trust?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You can join the Trust for just £10 a year <a href='https://www.lufctrust.com/membership'>via their site</a>"
        }
      }, 
      {
        "@type": "Question",
        "name": "What do I get for joining the Trust?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "As a member, you will be able to have your say on these important matters through our regular member surveys and we will give you exclusive updates on our discussions with the club. You also get a yearly membership pack which contains an ever-evolvoing collection of goodies and memorabilia.</a>"
        }
      }]
    `}
        </script>
      </Helmet>
    </>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
