import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "gatsby";

import CloseIcon from "../assets/images/icons/close.svg";

const MENU_ITEMS = [
  { title: "Home", link: "/", type: "internal" },
  { title: "About Us", link: "/about", type: "internal" },
  { title: "Articles", link: "/articles", type: "internal" },
  {
    title: "Mural Map",
    link: "https://murals.lufctrust.com",
    type: "external",
  },
  { title: "Objectives", link: "/about#objectives", type: "internal" },
  { title: "Join", link: "/membership", type: "internal" },
  {
    title: "Login",
    type: "external",
    link: "https://membership.lufctrust.com/login",
  },
];

const MobileMenuContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #2c3445b5;
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  backface-visibility: hidden;
  transform: translateZ(0) scale(1, 1);
  will-change: transform;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-font-smoothing: subpixel-antialiased;

  z-index: 100;
`;

const MobileMenuInnerContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 100;
`;

const TopLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px;
`;

const CloseContainer = styled(motion.div)`
  color: #fff;
  font-size: 30px;
  height: 20px;
  width: 20px;
`;

const LogoContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 28px;
  color: #fff;
  font-size: 32px;
  font-family: "Metropolis", Arial, Helvetica, sans-serif;

  font-weight: bold;
  user-select: none;
  cursor: pointer;
  span {
    color: #fde115;
  }
`;

const MenuItemsContainer = styled(motion.div)`
  padding: 0px 30px;
`;

const MenuItem = styled(motion.div)`
  font-family: "GT Walsheim", Arial, Helvetica, sans-serif;

  font-size: 35px;
  line-height: 40px;
  margin: 30px 0px;
  color: #fff;
  font-weight: 400;
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  color: unset;
`;

const MenuLinkExternal = styled.a`
  text-decoration: none;
  color: unset;
`;

const MenuContainerAnim = {
  hidden: {
    y: "-100vh",
    transition: {
      duration: 0.6,
      delay: 0.3,
      ease: "easeOut",
    },
  },
  visible: {
    y: 0,
    transition: {
      duration: 0.4,
    },
  },
};

const MenuItemsContainerAnim = {
  hidden: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
      when: "afterChildren",
      delayChildren: 0,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
      when: "afterChildren",
      delayChildren: 0.35,
    },
  },
};

const MenuItemAnim = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};

const CloseAnim = {
  hidden: {
    y: "-50px",
    opacity: 0,
    transition: {
      duration: 0.6,
    },
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.4,
    },
  },
};

const LogoAnim = {
  hidden: {
    y: "-50px",
    opacity: 0,
    transition: {
      duration: 0.6,
    },
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.3,
    },
  },
};

function MobileMenu({ active, toggleMobileMenu }) {
  return (
    <MobileMenuContainer
      initial="hidden"
      animate={active ? "visible" : "hidden"}
      variants={MenuContainerAnim}
    >
      <MobileMenuInnerContainer>
        <TopLayer>
          <LogoContainer
            initial="hidden"
            animate={active ? "visible" : "hidden"}
            variants={LogoAnim}
          >
            <Link to={"/"} style={{ textDecoration: "none", color: "unset" }}>
              <div>LU</div>
              <div>
                <span>ST</span>
              </div>
            </Link>
          </LogoContainer>
          <CloseContainer
            onClick={() => toggleMobileMenu()}
            initial="hidden"
            animate={active ? "visible" : "hidden"}
            variants={CloseAnim}
          >
            <CloseIcon stroke={"#fff"} fill={"#fff"} />
          </CloseContainer>
        </TopLayer>
        <MenuItemsContainer
          variants={MenuItemsContainerAnim}
          initial="hidden"
          animate={active ? "visible" : "hidden"}
        >
          {MENU_ITEMS.map((item, i) => (
            <MenuItem variants={MenuItemAnim}>
              {item.type === "internal" ? (
                <MenuLink to={item.link}>{item.title}</MenuLink>
              ) : (
                <MenuLinkExternal href={item.link}>
                  {item.title}
                </MenuLinkExternal>
              )}
            </MenuItem>
          ))}
        </MenuItemsContainer>
      </MobileMenuInnerContainer>
    </MobileMenuContainer>
  );
}

export default MobileMenu;
