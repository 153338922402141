import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "gatsby";

import ChevronRight from "../assets/images/icons/chevronRight.svg";

const CtaButtonContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => props.padding};
  border-radius: 30px;

  text-decoration: none;
  transition: 400ms;
  :hover {
    background-color: ${(props) => props.backgroundColorHover};
    transition: 400ms;
  }
`;

const Text = styled.div`
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  text-decoration: none;
`;

const Icon = styled(motion.div)`
  width: 7px;
  margin-left: 10px;
  padding-top: 2px;
`;

function CtaButton({
  text,
  link,
  backgroundColor,
  backgroundColorHover,
  color,
  bold,
  padding,
  mode,
}) {
  const [hovered, setHovered] = useState(false);
  return (
    <div>
      {mode !== "inactive" ? (
        <Link to={link} style={{ textDecoration: "none" }}>
          <CtaButtonContainer
            onHoverStart={() => setHovered(true)}
            onHoverEnd={() => setHovered(false)}
            backgroundColor={backgroundColor ? backgroundColor : "#fde115"}
            backgroundColorHover={
              backgroundColorHover ? backgroundColorHover : "#ffef78"
            }
            padding={padding ? padding : "7px 25px"}
          >
            <Text
              color={color ? color : "#000"}
              fontWeight={bold ? "bold" : 500}
            >
              {text}
            </Text>
            {mode !== "inactive" && (
              <Icon animate={{ x: hovered ? 5 : 0 }}>
                <ChevronRight fill={color ? color : "#000"} />
              </Icon>
            )}
          </CtaButtonContainer>
        </Link>
      ) : (
        <div style={{ textDecoration: "none" }}>
          <CtaButtonContainer
            onHoverStart={() => setHovered(true)}
            onHoverEnd={() => setHovered(false)}
            backgroundColor={backgroundColor ? backgroundColor : "#fde115"}
            backgroundColorHover={
              backgroundColorHover ? backgroundColorHover : "#ffef78"
            }
            padding={padding ? padding : "7px 25px"}
          >
            <Text
              color={color ? color : "#000"}
              fontWeight={bold ? "bold" : 500}
            >
              {text}
            </Text>
            {mode !== "inactive" && (
              <Icon animate={{ x: hovered ? 5 : 0 }}>
                <ChevronRight fill={color ? color : "#000"} />
              </Icon>
            )}
          </CtaButtonContainer>
        </div>
      )}
    </div>
  );
}

export default CtaButton;
