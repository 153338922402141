function linkParser(link) {
  if (!linkParser.isValidUrl(link)) {
    return;
  }

  if (link.url) {
    return link.url;
  }

  if (link.email) {
    return 'mailto:' + link.email;
  }

  if (link.cached_url) {
    return '/' + link.cached_url;
  }

  return '#nullLink';
}

linkParser.isValidUrl = function (link) {
  return link && (link.url || link.email || link.cached_url);
};

export default linkParser;
