/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { ParallaxProvider } from "react-scroll-parallax";
import { motion } from "framer-motion";
import { isIE } from "react-device-detect";

import Header from "./header";
import SEO from "./seo";
import AlternateMenu from "./alternateMenu";
import DesktopMenu from "./navigation/desktopMenu";
import Footer from "./footer";
import "./layout.css";
import "../assets/fonts/fonts.css";
import IeSupport from "./IeSupport";

const LayoutContainer = styled.div`
  font-family: "Poppins", sans-serif;
`;

const HeaderContainer = styled(motion.div)``;

const LayoutInnerContainer = styled(motion.div)``;

const Layout = ({
  children,
  title,
  description,
  sharingImage,
  articleMode,
  articleRootMode,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  let homeHeaderMode = false;
  let homeHeaderItemColor = "#FFFFFF";
  let homeHeaderLoginItemColor = "#FDE115";
  let mobileMenuIconColor = "#2C3445";
  let headerTransitionTriggerHeight = "100";
  let blueLogoMobileTop = true;
  let blueLogoTop = true;

  let mobileLogoTopColor = "#2C3445";
  let mobileMenuIconColorStandard = "#2C3445";
  let logoTopLayerColor = "#ffffff";
  let logoBottomLayerColor = "#FDE115";
  let menuItemsTopColor = { color: "#ffffff" };
  let standardHeaderTransitionTriggerHeight = "5";

  if (
    children &&
    children.props &&
    children.props.pageContext &&
    children.props.pageContext.story.content
  ) {
    let content = JSON.parse(children.props.pageContext.story.content);
    homeHeaderMode = content.homeHeaderMode;
    if (content.menuItemsColor) {
      homeHeaderItemColor = content.menuItemsColor.color;
    }
    if (content.menuLoginItemsColor) {
      homeHeaderLoginItemColor = content.menuLoginItemsColor.color;
    }
    if (content.headerTransitionTriggerHeight) {
      headerTransitionTriggerHeight = content.headerTransitionTriggerHeight;
    }
    if (content.mobileMenuIconColor) {
      mobileMenuIconColor = content.mobileMenuIconColor.color;
    }
    if (
      content.blueLogoMobileTop !== null &&
      content.blueLogoMobileTop !== undefined
    ) {
      blueLogoMobileTop = content.blueLogoMobileTop;
    }
    if (content.blueLogoTop !== null && content.blueLogoTop !== undefined) {
      blueLogoTop = content.blueLogoTop;
    }
    if (
      content.mobileLogoTopColor !== null &&
      content.mobileLogoTopColor !== undefined
    ) {
      mobileLogoTopColor = content.mobileLogoTopColor;
    }
    if (
      content.logoTopLayerColor !== null &&
      content.logoTopLayerColor !== undefined
    ) {
      logoTopLayerColor = content.logoTopLayerColor;
    }
    if (
      content.logoBottomLayerColor !== null &&
      content.logoBottomLayerColor !== undefined
    ) {
      logoBottomLayerColor = content.logoBottomLayerColor;
    }
    if (
      content.mobileMenuIconTopColor !== null &&
      content.mobileMenuIconTopColor !== undefined
    ) {
      mobileMenuIconColorStandard = content.mobileMenuIconTopColor;
    }
    if (
      content.menuItemsTopColor !== null &&
      content.menuItemsTopColor !== undefined
    ) {
      menuItemsTopColor = content.menuItemsTopColor;
    }
    if (
      content.standardHeaderTransitionTriggerHeight !== null &&
      content.standardHeaderTransitionTriggerHeight !== undefined
    ) {
      standardHeaderTransitionTriggerHeight =
        content.standardHeaderTransitionTriggerHeight;
    }
  } else if (children.props && children.props.children) {
    homeHeaderMode =
      children.props.children.props.children.props.homeHeaderMode;
    if (children.props.children.props.children.props.menuItemsColor) {
      homeHeaderItemColor =
        children.props.children.props.children.props.menuItemsColor.color;
    }
    if (children.props.children.props.children.props.menuLoginItemsColor) {
      homeHeaderLoginItemColor =
        children.props.children.props.children.props.menuLoginItemsColor.color;
    }
    if (
      children.props.children.props.children.props.headerTransitionTriggerHeight
    ) {
      headerTransitionTriggerHeight =
        children.props.children.props.children.props
          .headerTransitionTriggerHeight;
    }
    if (children.props.children.props.children.props.mobileMenuIconColor) {
      mobileMenuIconColor =
        children.props.children.props.children.props.mobileMenuIconColor.color;
    }
    if (
      children.props.children.props.children.props.blueLogoTop !== null &&
      children.props.children.props.children.props.blueLogoTop !== undefined
    ) {
      blueLogoTop = children.props.children.props.children.props.blueLogoTop;
    }
    if (
      children.props.children.props.children.props.blueLogoMobileTop !== null &&
      children.props.children.props.children.props.blueLogoMobileTop !==
        undefined
    ) {
      blueLogoMobileTop =
        children.props.children.props.children.props.blueLogoMobileTop;
    }
    if (
      children.props.children.props.children.props.mobileLogoTopColor !==
        null &&
      children.props.children.props.children.props.mobileLogoTopColor !==
        undefined
    ) {
      mobileLogoTopColor =
        children.props.children.props.children.props.mobileLogoTopColor;
    }
    if (
      children.props.children.props.children.props.logoTopLayerColor !== null &&
      children.props.children.props.children.props.logoTopLayerColor !==
        undefined
    ) {
      logoTopLayerColor =
        children.props.children.props.children.props.logoTopLayerColor;
    }
    if (
      children.props.children.props.children.props.logoBottomLayerColor !==
        null &&
      children.props.children.props.children.props.logoBottomLayerColor !==
        undefined
    ) {
      logoBottomLayerColor =
        children.props.children.props.children.props.logoBottomLayerColor;
    }
    if (
      children.props.children.props.children.props.mobileMenuIconTopColor !==
        null &&
      children.props.children.props.children.props.mobileMenuIconTopColor !==
        undefined
    ) {
      mobileMenuIconColorStandard =
        children.props.children.props.children.props.mobileMenuIconTopColor;
    }
    if (
      children.props.children.props.children.props.menuItemsTopColor !== null &&
      children.props.children.props.children.props.menuItemsTopColor !==
        undefined
    ) {
      menuItemsTopColor =
        children.props.children.props.children.props.menuItemsTopColor;
    }
    if (
      children.props.children.props.children.props
        .standardHeaderTransitionTriggerHeight !== null &&
      children.props.children.props.children.props
        .standardHeaderTransitionTriggerHeight !== undefined
    ) {
      standardHeaderTransitionTriggerHeight =
        children.props.children.props.children.props
          .standardHeaderTransitionTriggerHeight;
    }
  }

  if (articleMode) {
    menuItemsTopColor = { color: "#ffffff" };
    logoTopLayerColor = { color: "#ffffff" };
    logoBottomLayerColor = { color: "#FDE115" };
    mobileLogoTopColor = { color: "#ffffff" };
    mobileMenuIconColorStandard = { color: "#ffffff" };
    mobileMenuIconColor = { color: "#ffffff" };
  }

  if (articleRootMode) {
    menuItemsTopColor = { color: "#232530" };
    logoTopLayerColor = { color: "#ffffff" };
    logoBottomLayerColor = { color: "#FDE115" };
  }

  const [desktopMenuActive, setDesktopMenuActive] = useState(false);

  useEffect(() => {
    if (desktopMenuActive) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "unset";
    }
  }, [desktopMenuActive]);

  if (isIE) return <IeSupport />;

  return (
    <ParallaxProvider>
      <LayoutContainer>
        <HeaderContainer>
          <DesktopMenu
            active={desktopMenuActive}
            setDesktopMenuActive={setDesktopMenuActive}
          />
          {homeHeaderMode ? (
            <AlternateMenu
              homeHeaderItemColor={homeHeaderItemColor}
              transitionHeight={headerTransitionTriggerHeight}
              homeHeaderLoginItemColor={homeHeaderLoginItemColor}
              mobileMenuIconColor={mobileMenuIconColor}
              siteTitle={data.site.siteMetadata.title}
              blueLogoMobileTop={blueLogoMobileTop}
              blueLogoTop={blueLogoTop}
              setDesktopMenuActive={setDesktopMenuActive}
              menuItemsTopColor={menuItemsTopColor}
            />
          ) : (
            <Header
              siteTitle={data.site.siteMetadata.title}
              mobileLogoTopColor={mobileLogoTopColor}
              mobileMenuIconColorStandard={mobileMenuIconColorStandard}
              logoTopLayerColor={logoTopLayerColor}
              logoBottomLayerColor={logoBottomLayerColor}
              menuItemsTopColor={menuItemsTopColor}
              standardHeaderTransitionTriggerHeight={
                standardHeaderTransitionTriggerHeight
              }
              setDesktopMenuActive={setDesktopMenuActive}
            />
          )}
        </HeaderContainer>

        <LayoutInnerContainer>
          <main>{children}</main>
        </LayoutInnerContainer>
        <Footer />
      </LayoutContainer>
    </ParallaxProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
