import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { Link, useStaticQuery, graphql } from "gatsby";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import LinkParser from "../utils/link-parser";
import { detect } from "detect-browser";

import MobileMenu from "../components/mobileMenu";

import MenuIcon from "../assets/images/icons/menu.svg";

const HeaderSpacer = styled.div`
  width: 100%;
  height: 100px;
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  margin: 0px 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  width: 100%;
  display: block;
`;

const MobileContent = styled.div`
  display: none;
  z-index: 9;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 70px;
    margin: 0;
    padding: 0px 10px;
  }
`;

const MobileMenuBg = styled(motion.div)`
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgb(45 53 69 / 0.82);
  backface-visibility: hidden;
  transform: translateZ(0) scale(1, 1);
  will-change: transform;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-font-smoothing: subpixel-antialiased;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  width: 100%;
  height: 70px;
  ${(props) =>
    !props.notSupported &&
    css`
      background-filter: unset;
      background-color: #2c3445;
    `}
`;

const LeftContent = styled(motion.div)``;

const CenterContent = styled.div``;

const RightContent = styled(motion.div)`
  display: flex;
  font-family: "GT Walsheim", Arial, Helvetica, sans-serif;

  margin-right: 40px;
  padding: 17px;
  color: #fff;
  position: relative;
  z-index: 5;
  @media (max-width: 768px) {
    display: none;
  }
`;

const RightContentBg = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255, 255, 255, 0.72);
  backface-visibility: hidden;
  transform: translateZ(0) scale(1, 1);
  will-change: transform;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-font-smoothing: subpixel-antialiased;
  ${(props) =>
    !props.notSupported &&
    css`
      background-filter: unset;
      background-color: #2c3445;
    `}
`;

const RightContentMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 40px;
  }
`;

const MobileMenuIconContainer = styled.div`
  height: 35px;
  width: 35px;
  z-index: 9;
`;

const LogoContainerOuter = styled(Link)`
  height: 60px;
  text-decoration: none;
`;

const LogoContainer = styled(motion.div)`
  height: 60px;
  background-color: #2c3445;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Metropolis", Arial, Helvetica, sans-serif;

  font-weight: bold;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileLogoContainer = styled(motion.div)`
  display: none;
  @media (max-width: 768px) {
    height: 60px;
    width: 60px;
    background-color: #2c3445;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Metropolis", Arial, Helvetica, sans-serif;

    font-weight: bold;
    user-select: none;
    cursor: pointer;
    overflow: hidden;
  }
`;

const ExpandedLogoContainer = styled(motion.div)`
  padding: 0px 10px;
  text-transform: uppercase;
  font-size: 18px;

  line-height: 18px;
  a {
    color: unset;
    text-decoration: none;
  }
`;

const TopLayerLogo = styled.div`
  color: ${(props) => props.color};
  white-space: nowrap;
  a {
    color: unset;
    text-decoration: none;
  }
`;

const BottomLayerLogo = styled.div`
  color: ${(props) => props.color};
  white-space: nowrap;
  a {
    color: unset;
    text-decoration: none;
  }
`;

const CompressedLogoContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 22px;
  color: #fff;
  font-size: 26px;
  font-family: "Metropolis", Arial, Helvetica, sans-serif;

  font-weight: bold;
  line-height: 24px;
  font-size: 28px;
  letter-spacing: 2px;
  padding-top: 3px;
  padding-left: 3px;
  span {
    color: #fde115;
  }
`;

const CompressedLogoContainerMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 22px;
  color: ${(props) => props.color};
  width: 50px;
  height: 50px;
  font-size: 24px;
  letter-spacing: 3px;
  text-align: center;
  font-family: "Metropolis", Arial, Helvetica, sans-serif;

  font-weight: bold;
  padding-left: 3px;
  z-index: 9;
  span {
    color: ${(props) => props.accentColor};
  }
`;

const MenuItemContainer = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  z-index: 10;
`;

const MenuItem = styled(motion.div)`
  padding: 0px 10px;
  position: relative;
  color: ${(props) => props.menuColor};
  transition: 400ms;
  z-index: 10;
  font-weight: 600;
  :hover {
    transition: 400ms;
  }
  @media (max-width: 1540px) {
    color: ${(props) => props.menuColor};
  }
  ::after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: "";
    display: block;
    height: 1px;
    left: 0%;
    margin-top: 0px;
    position: absolute;
    background: ${(props) => props.menuColor};
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
    margin-left: 5%;
  }

  :hover {
    ::after {
      width: 90%;
      left: 0;
    }
  }
`;

const MenuIconDesktopContainer = styled(motion.div)`
  height: 35px;
  width: 35px;
  z-index: 9;
  margin-left: 15px;
  margin-top: -4px;
  cursor: pointer;
`;

const LogoAnim = {
  compressed: {
    width: "60px",
    height: "60px",
    transition: {
      duration: 0.4,
      type: "tween",
    },
  },
  expanded: {
    width: "225px",
    height: "60px",
    transition: {
      duration: 0.6,
      type: "tween",
    },
  },
};

const CompressedLogoAnim = {
  compressed: {
    opacity: 1,
    width: "60px",
    transition: {
      delay: 0.2,
      duration: 1,
    },
  },
  expanded: {
    opacity: 0,
    width: "300px",
    transition: {
      delay: 0.2,
      duration: 1,
    },
  },
};

const ExpandedLogoAnim = {
  compressed: {
    opacity: 0,
    transition: {
      delay: 0.2,
      duration: 1,
    },
  },
  expanded: {
    opacity: 1,
    transition: {
      delay: 0.3,
      duration: 0.4,
    },
  },
};

const MobileMenuBgAnim = {
  compressed: {
    opacity: 0,
    transition: {
      duration: 0.4,
    },
  },
  expanded: {
    opacity: 1,
    transition: {
      duration: 0.4,
    },
  },
};

const RightContentAnim = {
  compressed: {
    opacity: 1,
  },
  expanded: {
    opacity: 1,
    transition: {
      staggerChildren: 0.075,
      delayChildren: 0.4,
    },
  },
};

const RightContentItemAnim = {
  compressed: { opacity: 0, y: -20 },
  expanded: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
    },
  },
};

const LogoIntroAnim = {
  compressed: {
    opacity: 0,
    y: -40,
    transition: {
      delay: 0.2,
      duration: 1,
    },
  },
  expanded: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2,
      duration: 0.4,
    },
  },
};

const RightContentBgAnim = {
  hidden: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0.2,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      duration: 0.2,
    },
  },
};

const MenuIconContainerAnim = {
  compressed: { opacity: 0, y: -20 },
  expanded: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.9,
      duration: 0.35,
    },
  },
};

function Header({
  mobileLogoTopColor,
  mobileMenuIconColorStandard,
  logoTopLayerColor,
  logoBottomLayerColor,
  menuItemsTopColor,
  standardHeaderTransitionTriggerHeight,
  setDesktopMenuActive,
}) {
  const data = useStaticQuery(graphql`
    query Menu {
      menu: allStoryblokEntry(filter: { field_component: { eq: "menu" } }) {
        edges {
          node {
            content
          }
        }
      }
    }
  `);

  const [showExpandedLogo, setShowExpandedLogo] = useState(true);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [scrolledPast, setScrolledPast] = useState(false);
  const [firstTimeScroll, setFirstTimeScroll] = useState(true);
  const [doAnim, setDoAnim] = useState(true);

  let menuData = JSON.parse(data.menu.edges[0].node.content);
  menuData = menuData.menuItems;

  useEffect(() => {
    setTimeout(() => {
      setShowExpandedLogo(false);
    }, 2000);
  }, []);

  function toggleMobileMenu() {
    if (mobileMenuActive) {
      setMobileMenuActive(false);
    } else {
      setMobileMenuActive(true);
    }
  }

  useScrollPosition(({ prevPos, currPos }) => {
    let vh = Math.round(window.innerHeight / 100);
    let fullVh = vh * standardHeaderTransitionTriggerHeight;
    let fullVhConv = fullVh - fullVh * 2;
    if (currPos.y < fullVhConv) {
      if (firstTimeScroll) {
        setScrolledPast(true);
        setFirstTimeScroll(false);
      } else {
        setScrolledPast(true);
        setShowExpandedLogo(false);
      }
    } else {
      if (!firstTimeScroll) {
        setScrolledPast(false);
        setShowExpandedLogo(true);
      }
    }
  });

  let browser = detect();

  return (
    <React.Fragment>
      {mobileMenuActive ? (
        <MobileMenu
          active={mobileMenuActive}
          toggleMobileMenu={toggleMobileMenu}
        />
      ) : null}
      <HeaderSpacer />
      <HeaderContainer>
        <LeftContent
          initial="compressed"
          animate={doAnim ? "expanded" : "compressed"}
          variants={LogoIntroAnim}
        >
          <LogoContainerOuter to={"/"} style={{textDecoration: "none", color: "white"}}>
            <LogoContainer
              initial="expanded"
              animate={showExpandedLogo ? "expanded" : "compressed"}
              variants={LogoAnim}
              onHoverStart={() => setShowExpandedLogo(true)}
              onHoverEnd={() => setShowExpandedLogo(true)}
            >
              {showExpandedLogo ? (
                <ExpandedLogoContainer
                  initial={{
                    opacity: 1,
                  }}
                  animate={showExpandedLogo ? "expanded" : "compressed"}
                  variants={ExpandedLogoAnim}
                >
                  <TopLayerLogo color={logoTopLayerColor.color}>
                    <div>Leeds United</div>
                  </TopLayerLogo>
                  <BottomLayerLogo color={logoBottomLayerColor.color}>
                    <div>Supporters' Trust</div>
                  </BottomLayerLogo>
                </ExpandedLogoContainer>
              ) : (
                <CompressedLogoContainer
                  initial="expanded"
                  animate={showExpandedLogo ? "expanded" : "compressed"}
                  variants={CompressedLogoAnim}
                >
                  <div>LU</div>
                  <div>
                    <span>ST</span>
                  </div>
                </CompressedLogoContainer>
              )}
            </LogoContainer>
          </LogoContainerOuter>
        </LeftContent>
        <CenterContent></CenterContent>
        <RightContent
          initial="compressed"
          animate={doAnim ? "expanded" : "compressed"}
          variants={RightContentAnim}
        >
          {menuData.map((item, i) => (
            <MenuItemContainer to={LinkParser(item.link)} key={i}>
              <MenuItem
                menuColor={
                  scrolledPast
                    ? "#fff"
                    : menuItemsTopColor
                    ? menuItemsTopColor.color
                    : "#000"
                }
                key={i}
                variants={RightContentItemAnim}
              >
                {item.title}
              </MenuItem>
            </MenuItemContainer>
          ))}
          <MenuIconDesktopContainer
            onClick={() => setDesktopMenuActive(true)}
            initial="compressed"
            animate={doAnim ? "expanded" : "compressed"}
            variants={MenuIconContainerAnim}
          >
            <MenuIcon
              fill={
                scrolledPast
                  ? "#fff"
                  : menuItemsTopColor
                  ? menuItemsTopColor.color
                  : "#000"
              }
              stroke={
                scrolledPast
                  ? "#fff"
                  : menuItemsTopColor
                  ? menuItemsTopColor.color
                  : "#000"
              }
            />
          </MenuIconDesktopContainer>
          <RightContentBg
            initial="hidden"
            animate={scrolledPast ? "visible" : "hidden"}
            variants={RightContentBgAnim}
            notSupported={
              browser.name === "chrome"
                ? true
                  ? browser.name === "safari"
                  : true
                : false
            }
          />
        </RightContent>
      </HeaderContainer>
      <MobileContent>
        <CompressedLogoContainerMobile
          color={
            scrolledPast
              ? "#FFFFFF"
              : mobileLogoTopColor
              ? mobileLogoTopColor.color
              : "#000"
          }
          accentColor={
            scrolledPast
              ? "#FDE115"
              : mobileLogoTopColor
              ? mobileLogoTopColor.color
              : "#000"
          }
        >
          <Link to={"/"} style={{ textDecoration: "none", color: "unset" }}>
            <div>LU</div>
            <div>
              <span>ST</span>
            </div>
          </Link>
        </CompressedLogoContainerMobile>

        <MobileMenuIconContainer onClick={() => setMobileMenuActive(true)}>
          <MenuIcon
            fill={
              scrolledPast
                ? "#FFFFFF"
                : mobileMenuIconColorStandard
                ? mobileMenuIconColorStandard.color
                : "#000"
            }
            stroke={
              scrolledPast
                ? "#FFFFFF"
                : mobileMenuIconColorStandard
                ? mobileMenuIconColorStandard.color
                : "#000"
            }
          />
        </MobileMenuIconContainer>
        <MobileMenuBg
          initial="expanded"
          animate={scrolledPast ? "expanded" : "compressed"}
          variants={MobileMenuBgAnim}
          notSupported={
            browser.name === "chrome"
              ? true
                ? browser.name === "safari"
                : true
              : false
          }
        />
      </MobileContent>
    </React.Fragment>
  );
}

export default Header;
