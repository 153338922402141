import React, { useState } from "react";
import styled from "styled-components";
import EllipsisText from "react-ellipsis-text";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import CtaButton from "../ctaButton";
import { navigate } from "gatsby";

const NewsItemContainerOuter = styled(Link)`
  height: 100%;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  z-index: 10;
`;

const NewsItemContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  background-color: #10192d;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 20px;
  overflow: hidden;
`;

const BackgroundTile = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #10192d, #4d556796),
    url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
`;

const ContentContainer = styled.div`
  z-index: 2;
`;

const Title = styled(EllipsisText)`
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
`;

const InnerContentContainer = styled(motion.div)`
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
`;

const Description = styled(EllipsisText)`
  font-size: 13px;
  line-height: 15px;
  color: #fff;
`;

const CtaContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  transform: scale(0.7);
  margin-left: -30px;
  margin-top: 10px;
`;

const BackgroundAnim = {
  hidden: { scale: 1.2, opacity: 1 },
  visible: {
    scale: 1,
    opacity: 0.4,
    transition: {
      duration: 0.8,
    },
  },
};

const AdditionalAnim = {
  hidden: {
    height: 0,
    marginTop: 0,
  },
  visible: {
    height: "fit-content",
    marginTop: "25px",
    transition: {
      duration: 0.4,
    },
  },
};

function NewsItem({ closeMenu, data }) {
  let content = JSON.parse(data.content);
  const [isHovered, setIsHovered] = useState(false);

  function handleSelection(url) {
    closeMenu();
    setTimeout(() => {
      navigate(url);
    }, 500);
  }

  return (
    <NewsItemContainerOuter>
      <NewsItemContainer
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        onClick={() => handleSelection("/" + data.full_slug)}
      >
        <ContentContainer>
          <Title text={data.name ? data.name : ""} length={"60"} />
          <InnerContentContainer
            initial="hidden"
            animate={isHovered ? "visible" : "hidden"}
            variants={AdditionalAnim}
          >
            <Description
              text={content.postSnippet ? content.postSnippet : ""}
              length={100}
            />
            <CtaContainer>
              <CtaButton
                text={"Read More"}
                backgroundColor={"transparent"}
                backgroundColorHover={"transparent"}
                color={"#fff"}
                padding={"0px"}
              />
            </CtaContainer>
          </InnerContentContainer>
        </ContentContainer>

        <BackgroundTile
          backgroundImage={content.thumbnailImage.filename}
          initial="hidden"
          animate={isHovered ? "visible" : "hidden"}
          variants={BackgroundAnim}
        />
      </NewsItemContainer>
    </NewsItemContainerOuter>
  );
}

export default NewsItem;
