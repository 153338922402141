import React from "react";
import styled from "styled-components";

const IeSupportContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #2d3545;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: sans-serif;
  text-align: center;
`;

const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const Subtitle = styled.div`
  font-size: 18px;
  line-height: 125%;
  max-width: 500px;
  margin-bottom: 20px;
  a {
    color: yellow;
  }
`;

function IeSupport() {
  return (
    <IeSupportContainer>
      <Title>Uh oh, your browser is too old to view this!</Title>
      <Subtitle>
        You can still{" "}
        <a href="https://www.membership.lufctrust.com/join">join as a member</a>
        , however, you'll need to upgrade your browser to view this content.
      </Subtitle>

      <Subtitle>
        Already a member?{" "}
        <a href="https://www.membership.lufctrust.com/login">
          Click here to login
        </a>
      </Subtitle>
    </IeSupportContainer>
  );
}

export default IeSupport;
